import axios from "axios";
import { ALL_TIME_ZONE_GET } from "./URLS";

export const getAllTimeZones = async (data) => {
    try {
        const URL = ALL_TIME_ZONE_GET;
        // const Config = {};
        return await axios.get(URL).then(res => res?.data)
    } catch (err) {
        console.log("error in login==>", err)
    }
}