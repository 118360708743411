export const ALL_USER_DETAILS = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/retrieve`;

export const CREATE_USER = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/create/new/user`;

export const DELETE_USER = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/delete/user/details`;

export const PERFORMANCE_SUMMARY_ROUND1 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/get/developer/round1/performance/summary`;

export const ADMIN_LOGIN = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/login`;

export const ALL_TIME_ZONE_GET = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ROUND1}/api/v1/timezone/get`;

export const COUNTRY_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/country/getcountries`;

export const STATE_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/country/getstates`;

export const CITY_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/country/getcities`;

export const USA_STATE_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/countryusa/get/states`;

export const USA_CITY_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/countryusa/get/cities`;

export const ALL_DEV_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/dev/details`;

export const UPDATE_USER_DATA = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/update/user/details`;

export const GET_ROUND1_REPORT = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/get/developer/round1/question/answer`;

export const HANDLE_AVAILIBILITY_UPDATE = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/admin/api/v1/candidatesm/change/availability`;

//  candidate  progress
export const CANDIDATE_PROGRESS = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/developer/getprogress`;

// candidate details save and get
export const CANIDATE_PERSONAL_DETAILS_SAVE = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/dev/personaldetails`;

export const CANDIDATE_PERSONAL_DETAILS_UPDATE = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/dev/personaldetails/update/personaldetails`;

export const CANDIDATE_PERSONAL_DETAILS_GET = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/dev/personaldetails/getpersonaldetails`;

export const CANDIDATE_PROFESSIONAL_DETAILS_SAVE = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/skills`;

export const CANDIDATE_PROFESSIONAL_DETAILS_GET = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/skills/getskills`;

export const CANDIDATE_DETAILS_OPTIONS_DATA = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DEVELOPER}/api/v1/personaldetailspage/fulldetails`;

export const CANDIDATE_ROUND2_REPORT1 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/admin/api/v1/round2/report/screen1`;

export const CANDIDATE_ROUND2_REPORT2 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/admin/api/v1/round2/report/screen2`;

export const CANDIDATE_ROUND2_REPORT3 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/admin/api/v1/round2/report/screen3`;

export const SET_NEW_PASSWORD = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AUTHENTICATION}/api/v1/user/create/newpassword`;

export const UPDATE_USER_PROGRESS = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/delete/developer/rounds`;

export const GET_ALL_REFERRALS_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/user/admin/get/all/referred/candidate/details`;

export const SEARCH_USER_BY_ID = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/search/users`;

export const SEARCH_DEV_BY_ID = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/search/dev`;

export const FILTERS_USERS_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/user/filter`;

export const FILTERS_DEV_LIST = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN}/api/v1/admin/dev/filter`;

export const CANDIDATE_PROFILE = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CSM_SERVICE}/api/v1/candidatesm/developer/profile`;

export const CREATE_QUESTION_ROUND1 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ROUND1}/api/v1/round1/excel-to-json`;

export const CREATE_QUESTION_ROUND2 = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ROUND2}/api/v1/round2/export-to-json`;

export const VIEW_SCREEN_RECORDING = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ROUND3}/api/v1/round3/view/screen/recording`;

export const VIEW_VIDEO_RECORDING = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ROUND3}/api/v1/round3/view/video/recording`;
