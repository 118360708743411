import "./Round2Report.scss";
import icon1 from "../../Assessts/SVGs/assessment-report/attempted.svg";
import icon2 from "../../Assessts/SVGs/assessment-report/calendar.svg";
import icon3 from "../../Assessts/SVGs/assessment-report/calendar2.svg";
import icon5 from "../../Assessts/SVGs/assessment-report/failed.svg";
import icon6 from "../../Assessts/SVGs/assessment-report/target.svg";
import icon7 from "../../Assessts/SVGs/assessment-report/time.svg";
import icon8 from "../../Assessts/SVGs/assessment-report/doubleCheck.svg";
import icon9 from "../../Assessts/SVGs/assessment-report/singleCheck.svg";
import icon10 from "../../Assessts/SVGs/assessment-report/cross.svg";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css"; // Basic CodeMirror styling
import "codemirror/theme/material.css"; // Import the specific theme you plan to use
import "codemirror/mode/python/python"; // Import modes you need
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike"; // for Java and C++// Import additional language modes as needed
import { useEffect, useRef } from "react";

export const Round2Report = ({
  performanceR2,
  setViewCompleteReportR2,
  viewCompleteReportR2,
  selectedRound,
  roundValue,
  reportR2_1,
  reportR2_2,
}) => {
  return (
    <>
      <div className="round-report">
        <div className="data">
          <div style={{ border: "1px solid #DF7A03" }}>
            <div>
              <img src={icon6} alt="" />
            </div>
            <div>
              <div>
                {" "}
                Total Score :{" "}
                <span style={{ color: "#DF7A03" }}>
                  {" "}
                  {performanceR2?.score?.toFixed(2)}
                </span>
                / <span>100</span>
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #783ADE" }}>
            <div>
              <img src={icon5} alt="" />
            </div>
            <div>{performanceR2?.status}</div>
          </div>
          <div style={{ border: "1px solid #E4AD15" }}>
            <div>
              <img src={icon1} alt="" />
            </div>
            <div>
              <div>
                {" "}
                Question Attempted :{" "}
                <span style={{ color: "#E4AD15" }}>
                  {performanceR2?.questionsAttempted}{" "}
                </span>
                / <span>{performanceR2?.totalQuestions}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="title" style={{ marginTop: "50px" }}>
          Test Time Analysis
        </div>
        <div className="data">
          <div style={{ border: "1px solid #0A4FA0" }}>
            <div>
              <img src={icon7} alt="" />
            </div>
            <div>
              <div>
                {" "}
                Time Taken For Completion :{" "}
                <span style={{ color: "#0A4FA0" }}>
                  {" "}
                  {performanceR2?.timeTakenForCompletion}{" "}
                </span>
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #0D8AA5" }}>
            <div>
              <img src={icon2} alt="" />
            </div>
            <div>
              <div>
                {" "}
                Test Start Time :{" "}
                <span style={{ color: "#0D8AA5" }}>
                  {performanceR2?.startTime}{" "}
                </span>{" "}
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #AA0047" }}>
            <div>
              <img src={icon3} alt="" />
            </div>
            <div>
              <div>
                Test End Time :{" "}
                <span style={{ color: "#AA0047" }}>
                  {" "}
                  {performanceR2?.endTime}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="title" style={{ marginTop: "50px" }}>
          Performance Summary
        </div>
        <div className="data">
          <div style={{ border: "1px solid #34A853" }}>
            <div>
              <img src={icon8} alt="" />
            </div>
            <div>
              <div>
                Solutions accepted :{" "}
                <span style={{ color: "#34A853" }}>
                  {" "}
                  {performanceR2?.solutionsAccepted}{" "}
                </span>
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #34A853" }}>
            <div>
              <img src={icon9} alt="" />
            </div>
            <div>
              <div>
                {" "}
                Solutions partially accepted :{" "}
                <span style={{ color: "#34A853" }}>
                  {performanceR2?.solutionsPartiallyAccepted}{" "}
                </span>{" "}
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid #DE3A3B" }}>
            <div>
              <img src={icon10} alt="" />
            </div>
            <div>
              <div>
                Solutions rejected :{" "}
                <span style={{ color: "#DE3A3B" }}>
                  {" "}
                  {performanceR2?.solutionsRejected}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="view-report">
          <button onClick={() => setViewCompleteReportR2(true)}>
            View Complete Report
          </button>
        </div>
      </div>
      {viewCompleteReportR2 && (
        <>
          <CompleteReportR2
            selectedRound={selectedRound}
            roundValue={roundValue}
            performanceR2={performanceR2}
          />
          <Round2Summary reportR2_1={reportR2_1} />
          <DetailedReport reportR2_2={reportR2_2} />
        </>
      )}
    </>
  );
};

const CompleteReportR2 = ({ selectedRound, roundValue, performanceR2 }) => {
  return (
    <div className="assessment-report">
      <div className="top-group">
        <div className="round-num">
          <span> {selectedRound} </span>
          <span>
            {roundValue.find((item) => item.title === selectedRound)?.value}
          </span>
        </div>
        <div className="status">
          <div>
            Status : <span> {performanceR2?.status}</span>
          </div>
          <div>
            Final Score:{" "}
            <span>
              {performanceR2?.solutionsAccepted}/{" "}
              {performanceR2?.totalQuestions}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Round2Summary = ({ reportR2_1 }) => {
  return (
    <div className="summary-container">
      <div className="title">Summary</div>
      <div className="challenge-table">
        <div className="table-header">
          <div className="header-cell index-number">#</div>
          <div className="header-cell flex-cell">Problem Name</div>
          <div className="header-cell flex-cell">Status</div>
          <div className="header-cell flex-cell">Time Spent on Problem</div>
          <div className="header-cell flex-cell">Score</div>
        </div>
        {reportR2_1?.questionSummary?.map((item, index) => (
          <div className="table-row" key={index}>
            <div className="cell index-number">{index + 1}</div>
            <div className="cell flex-cell question">{item.problemName}</div>
            <div
              className={`cell status flex-cell ${item.status
                .toLowerCase()
                .replace(/\s+/g, "-")}`}
            >
              {item.status}
            </div>
            <div className="cell flex-cell">{item.timeSpent}</div>
            <div className="cell flex-cell">
              <span>{item.score.toFixed(2)}</span> / {item.totalScore}
            </div>
          </div>
        ))}
      </div>
      <div className="title">Technology used</div>
      <div className="techs">
        {reportR2_1?.technologiesUsed?.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </div>
    </div>
  );
};

const DetailedReport = ({ reportR2_2 }) => {
  return (
    <div className="detailed-report">
      {reportR2_2?.problemDetails?.map((item, index) => (
        <div key={index} className="each-question">
          <div className="score-main">
            <div className="titles">Problem {item?.problemNumber} :</div>
            <div className="score">
              <span> Score</span> {item?.scoreObtained.toFixed(2)} /{" "}
              {item?.score}
            </div>
          </div>
          <div className="titles">Problem Statement :</div>
          <div className="desc">{item?.problemStatement}</div>
          <div className="titles"> Description : </div>
          <div className="desc">{item?.description}</div>
          <div className="titles">Constraints : </div>
          <div className="desc">{item?.constraints}</div>
          <div className="titles">Example : </div>
          <div className="desc">
            <div>input : {item?.example?.input}</div>
            <div>output : {item?.example?.output}</div>
          </div>
          <TestCasesTable TestData={item?.testcases} />
          <SolutionData
            totalTestCases={item?.totalTestCases}
            testCasesPassed={item?.testCasesPassed}
            devTime={item?.devTime}
            status={item?.status}
            scoreObtained={item?.scoreObtained}
            score={item?.score}
          />
          <CodeEditor item={item} code={item?.code} language={item?.techUsed} />
        </div>
      ))}
    </div>
  );
};

const TestCasesTable = ({ TestData }) => {
  return (
    <table className="test-cases-table">
      <thead>
        <tr>
          <th>Test Cases</th>
          <th>Input</th>
          <th>Output</th>
        </tr>
      </thead>
      <tbody>
        {TestData.map((test, index) => (
          <tr key={index}>
            <td>Test Case {index + 1}</td>
            <td>{test.input}</td>
            <td>{test.expectedOutput}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const SolutionData = ({
  totalTestCases,
  testCasesPassed,
  devTime,
  status,
  scoreObtained,
  score,
}) => {
  return (
    <div className="solution-data">
      <div className="row-1">
        <div className="title">Solution :</div>
        <div className="row-1-1">
          <div className="item-1">{status}</div>
          <div className="item-2">
            Score : <span className="it2-1">{scoreObtained.toFixed(2)}</span> /{" "}
            <span>{score}</span>
          </div>
        </div>
      </div>
      <div className="row-2">
        <div className="item-1">
          Total Test Cases : <span>{totalTestCases}</span>
        </div>
        <div className="item-2">
          Test Cases Passed : <span>{testCasesPassed}</span>{" "}
        </div>
        <div className="item-3">
          Development Time : <span>{devTime}</span>
        </div>
      </div>
    </div>
  );
};

const CodeEditor = ({ language = "", code, item }) => {
  const languageMode = determineLanguageMode(language);
  const codemirrorRef = useRef(null);

  useEffect(() => {
    const current = codemirrorRef.current;
    if (current) {
      const { editor } = current;
      if (editor) {
        const updateEditorSize = () => {
          editor.setSize(null, "auto"); // Set width to null (auto) and height to content height
        };
        updateEditorSize(); // Initial size update
        editor.on("change", updateEditorSize); // Update size on every change
        return () => {
          editor.off("change", updateEditorSize);
        };
      }
    }
  }, []);

  return (
    <div className="code-editor-main">
      <div className="desc">
        Description :{" "}
        <span
          style={{
            color:
              item?.status?.toLowerCase() === "accepted"
                ? "#0BB762"
                : "#DE3A3B",
          }}
        >
          {item?.status}
        </span>
      </div>
      <CodeMirror
        ref={codemirrorRef}
        value={code}
        options={{
          mode: languageMode,
          theme: "white",
          lineNumbers: true,
          readOnly: true, // Set readOnly option to true
        }}
      />
    </div>
  );
};

const determineLanguageMode = (language) => {
  switch (language.toLowerCase()) {
    case "python":
      return "python";
    case "java":
      return "text/x-java";
    case "c++":
      return "text/x-c++src";
    case "js":
    case "javascript":
      return "javascript";
    default:
      return "text/plain"; // Default to plain text if language is not supported
  }
};
