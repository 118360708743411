import "./ViewDevelopers.scss";
// import { Input } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  filterDevList,
  getAllDevListData,
  searchDevById,
} from "../../APIs/getAllDevList";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import { TablePages } from "../../Components/common/Pagination_Data/Pagination_Data";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Alert } from "@mui/material";
import { getCountries } from "../../APIs/getLocation";
import { CandidateProfile } from "../../Components/CandidateProfile/CandidateProfile";
import LoadingOverlay from "../../Components/common/Loadingoverlay/LoadingOverlay";

const headersList = [
  "Email",
  "User Name",
  "Creation Date",
  "User Role",
  "Location",
  "Status",
  "Availability",
  "Progress",
];

export const ViewDevelopers = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(queryParams?.get("page")) || 1);

  const [filters, setFilters] = useState({
    creationDate: null,
    availableDate: null,
    location: null,
    userRole: null,
    status: null,
    progress: null,
    statusOfProgress: null,
  });

  const [name, setName] = useState("");
  const [tableHeaders, setTableHeaders] = useState([...headersList]);
  const [tableData, setTableData] = useState([]);
  const [timeOutId, setTimeOutId] = useState("");
  const [filterError, setFiltersError] = useState(false);
  const [viewFilters, setViewFilters] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setViewFilters(false);
  };

  const hasNonEmptyValue = (data) => {
    return Object.values(data).some((value) => value !== "");
  };

  const handleSearchDev = async (data) => {
    setViewFilters(false);
    try {
      if (hasNonEmptyValue(data)) {
        // If any key has a non-empty value, use filterDevList
        const res = await filterDevList(data);
        setTableData([...res]);
      } else {
        // If no key is present in data, use getAllDevList
        getAllDevList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`/view-developers?page=${value}`);
    // You can perform any other actions here, such as fetching data for the new page.
  };

  const getAllDevList = async () => {
    setFiltersError(false);
    try {
      const data = {
        // name: sessionStorage.getItem("email"),
        page: page - 1,
        size: 10,
      };
      const res = await getAllDevListData(data);
      setTableData([...res?.content]);
    } catch (error) {
      console.log(error);
      setTableData([]);
    }
  };

  const debounce = (func, delay) => {
    return function (...args) {
      clearTimeout(timeOutId);
      let id = setTimeout(() => func.apply(this, args), delay);
      setTimeOutId(id);
    };
  };

  const searchUserByName = debounce(async (value) => {
    try {
      // API call to search user by name
      const data = {
        adminEmail: sessionStorage.getItem("email"),
        searchEmail: value,
      };
      const res = await searchDevById(data);
      if (res) setTableData([...res]);
      else {
        setTableData([]);
        setFiltersError(true);
      }
    } catch (error) {
      console.error("Error searching user by name:", error);
      setTableData([]);
      setFiltersError(true);
    }
  }, 500);

  const handleNameChange = (value) => {
    setName(value);
    setFiltersError(false);
    if (value?.length >= 3) {
      // If name is not empty, debounce the search API call
      searchUserByName(value);
    } else if (value === "") {
      getAllDevList();
    }
  };

  const getAllLocationList = async () => {
    const res = await getCountries();
    setLocationOptions([...res]);
  };
  // Function to convert data to CSV format
  const convertToCSV = (data, headers) => {
    console.log("data to map =>", data);
    const headerRow = headers?.join(","); // Create the header row
    const dataRows = data?.map((item) => {
      return [
        item?.emailId || "--",
        item?.userName || "--",
        item?.creationDate || "--",
        item?.userRole || "--",
        item?.location || "--",
        item?.userStatus || "--",
        item?.availability || "--",
        item?.progress || "--",
        item?.statusOfProgress || "--",
      ].join(","); // Create data rows
    });

    return [headerRow, ...dataRows].join("\n"); // Combine header and data rows
  };

  const handleExportCLick = async () => {
    setLoading(true);
    try {
      const pageData = {
        page: null,
        size: null,
      };
      const data = {
        creationDate: null,
        availableDate: null,
        location: null,
        userRole: null,
        status: null,
        progress: null,
        statusOfProgress: null,
      };

      const res = await filterDevList(filters, pageData, false);

      const csv = convertToCSV(res?.content || res, tableHeaders);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllDevList();
  }, [page]);

  useEffect(() => {
    getAllLocationList();
  }, []);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <div className="view-developers">
        <div className="title">
          <div>Developers</div>
        </div>
        <div className="searchAndFilter">
          <SearchDevelopers
            name={name}
            handleNameChange={handleNameChange}
            filterError={filterError}
          />
          <div className="button-wrapper">
            <button
              className="export-button"
              onClick={() => handleExportCLick()}
            >
              Export Data
            </button>
            <button
              className="filersButton"
              onClick={() => setViewFilters(true)}
            >
              Filters <FilterListIcon />
            </button>
          </div>
        </div>
        <DisplayTableData tableHeaders={tableHeaders} tableData={tableData} />
        <TablePages page={page} handlePageChange={handlePageChange} />
        <FilterDialog
          open={viewFilters}
          handleClose={handleClose}
          handleSearchDev={handleSearchDev}
          locationOptions={locationOptions}
        />
      </div>
    </>
  );
};

const SearchDevelopers = ({ name, handleNameChange, filterError }) => {
  return (
    <div className="searchMain">
      <TextField
        sx={{ ml: 0, flex: 1, width: 400 }}
        label="Search by email ID"
        variant="outlined"
        value={name}
        onChange={(e) => handleNameChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: !!name,
        }}
      />
      {filterError && <Alert severity="error">No User Found!</Alert>}
    </div>
  );
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1), // Adjust as needed
  boxShadow: "none", // This line removes the box-shadow
}));

const progressData = ["Round1", "Round2", "Round3", "Round4", "Round5"];
const DisplayTableData = ({ tableHeaders, tableData }) => {
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});
  const [selectedProfile, setSelectedProfile] = useState("");
  const [viewProfile, setViewProfile] = useState(false);

  const handleClick = (event, index) => {
    setAnchorEls({ ...anchorEls, [index]: event.currentTarget });
  };

  const handleClose = (index) => {
    setAnchorEls({ ...anchorEls, [index]: null });
  };

  const handleViewReport = (email) => {
    // console.log(email);
    sessionStorage.setItem("view_report_user", email);
    navigate("/dev-report");
  };

  const handleEdit = (user) => {
    sessionStorage.setItem("edit_user_availaability", JSON.stringify(user));
    navigate("/edit-availability");
  };

  const handleViewDetails = (user) => {
    navigate(`/details/personal?user=${user}`);
  };

  const handleEditProgress = (user) => {
    sessionStorage.setItem("edit_user_progress", JSON.stringify(user));
    navigate("/edit-progress");
  };

  const handleViewProfile = (email) => {
    setSelectedProfile(email);
    setViewProfile(true);
  };

  const handleViewRecording = (email, round) => {
    navigate(`/dev-recording/screen?user=${email}&round=${round}`, {
      state: { back: true },
    });
  };

  return (
    <>
      {" "}
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              {tableHeaders?.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((user, index) => (
              <tr key={index}>
                <td>{user?.emailId || "--"}</td>
                <td>{user?.userName || "--"}</td>
                <td>{user?.creationDate || "--"}</td>
                <td>{user?.userRole || "--"}</td>
                <td>{user?.location || "--"}</td>
                <td
                  className={
                    user?.userStatus?.toLowerCase() === "active"
                      ? "active"
                      : user?.userStatus?.toLowerCase() === "inactive" ||
                        user?.userStatus?.toLowerCase() === "terminated"
                      ? "inactive"
                      : ""
                  }
                >
                  {user?.userStatus || "--"}
                </td>
                <td className="availability">
                  {user?.availability || "--"}{" "}
                  {user?.availability && (
                    <span>
                      {" "}
                      <EditIcon onClick={() => handleEdit(user)} />
                    </span>
                  )}
                </td>
                <td>{user?.progress || "--"}</td>
                <td className="details">
                  <IconButton onClick={(e) => handleClick(e, index)}>
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    anchorEl={anchorEls[index]}
                    open={Boolean(anchorEls[index])}
                    onClose={() => handleClose(index)}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose(index);
                        handleEditProgress(user);
                      }}
                    >
                      Edit progress
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleViewDetails(user?.emailId);
                        handleClose(index);
                      }}
                    >
                      View Details
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose(index);
                        handleViewProfile(user.emailId);
                      }}
                      disabled={user.progress !== "Round5"}
                    >
                      View Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleViewReport(user.emailId);
                        handleClose(index);
                      }}
                      disabled={!progressData.includes(user.progress)}
                    >
                      View Report
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleViewRecording(user.emailId, "round1");
                        handleClose(index);
                      }}
                      disabled={!progressData.includes(user.progress)}
                    >
                      View Round1 Recording
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleViewRecording(user.emailId, "round2");
                        handleClose(index);
                      }}
                      disabled={!progressData.includes(user.progress)}
                    >
                      View Round2 Recording
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(index)}>
                      Delete
                    </MenuItem>
                  </StyledMenu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <HandleViewProfile
        open={viewProfile}
        setViewProfile={setViewProfile}
        selectedProfile={selectedProfile}
        setSelectedProfile={setSelectedProfile}
      />
    </>
  );
};

const FilterDialog = ({
  open,
  handleClose,
  handleSearchDev,
  locationOptions,
}) => {
  const [filters, setFilters] = useState({
    creationDate: null,
    availableDate: null,
    location: null,
    userRole: null,
    status: null,
  });

  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          position: "absolute",
          top: 0,
          right: 0,
          margin: 2,
          width: 400,
        },
      }}
    >
      <DialogContent>
        <TextField
          type="date"
          label="Creation Date"
          name="creationDate"
          value={filters.creationDate}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          type="date"
          label="Available Date"
          name="availableDate"
          value={filters.availableDate}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          select
          label="Location"
          name="location"
          value={filters.location}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="">Select location</MenuItem>
          {locationOptions.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
          {/* Add more MenuItems as needed */}
        </TextField>

        <TextField
          select
          label="User Role"
          name="userRole"
          value={filters.userRole}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="">Select User role</MenuItem>
          <MenuItem value="Frontend">Frontend</MenuItem>
          <MenuItem value="Backend">Backend</MenuItem>
          <MenuItem value="Full Stack">Full Stack</MenuItem>
          <MenuItem value="Data science">Data science</MenuItem>
          {/* Add more MenuItems as needed */}
        </TextField>

        <RadioGroup
          name="status"
          value={filters.status}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="Active" control={<Radio />} label="Active" />
          <FormControlLabel
            value="Terminated"
            control={<Radio />}
            label="Terminated"
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() =>
            setFilters({
              creationDate: null,
              availableDate: null,
              location: null,
              userRole: null,
              status: null,
            })
          }
        >
          RESET
        </Button>
        <Button onClick={() => handleSearchDev(filters)}>SAVE</Button>
      </DialogActions>
    </Dialog>
  );
};

const HandleViewProfile = ({
  open,
  setViewProfile,
  selectedProfile,
  setSelectedProfile,
}) => {
  const handleClose = () => {
    setViewProfile(false);
    setSelectedProfile(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          position: "absolute",
          minWidth: "60vw",
        },
      }}
    >
      <CandidateProfile email={selectedProfile} handleClose={handleClose} />
    </Dialog>
  );
};
