import { useEffect, useRef, useState } from "react";
import "./DevReport.scss";
import { CustomDropDown } from "../../Components/common/CustomDropDown/CustomDropDown";

import icon1 from "../../Assessts/SVGs/assessment-report/attempted.svg";
import icon2 from "../../Assessts/SVGs/assessment-report/calendar.svg";
import icon3 from "../../Assessts/SVGs/assessment-report/calendar2.svg";
import icon4 from "../../Assessts/SVGs/assessment-report/correct.svg";
import icon5 from "../../Assessts/SVGs/assessment-report/failed.svg";
import icon6 from "../../Assessts/SVGs/assessment-report/target.svg";
import icon7 from "../../Assessts/SVGs/assessment-report/time.svg";
import icon8 from "../../Assessts/SVGs/Passed.svg";
import {
  allReportRound1,
  performanceSumaryRound1,
  performanceSumaryRound2,
  performanceSumaryRound21,
  performanceSumaryRound22,
} from "../../APIs/devReport";
import { Round2Report } from "../../Components/Round2Report/Round2Report";
import generatePDF from "react-to-pdf";
import { GlobalLoader } from "../../Components/common/GlobalLoader/GlobalLoader";
import useStickyElements from "../../hooks/useStickyElements";

const roundNumber = [
  "Round 1",
  "Round 2",
  // "Round 3", "Round 4", "Round 5"
];

export const DevReport = () => {
  const componentRef = useRef();
  const [roundsList, setRoudsList] = useState([...roundNumber]);
  const [selectedRound, setSelectedRound] = useState("Round 1");
  const [viewCompleteReport, setViewCompleteReport] = useState(false);
  const [viewCompleteReportR2, setViewCompleteReportR2] = useState(false);
  const [performanceR1, setPerformanceR1] = useState({});
  const [questionData, setQuestionData] = useState([]);
  const [reportR2_1, setReportR2_1] = useState({});
  const [reportR2_2, setReportR2_2] = useState({});
  const [roundValue, setRoundValue] = useState([
    {
      title: "Round 1",
      value: "Technical Fundamentals Test",
    },
    {
      title: "Round 2",
      value: "Coding Challenge",
    },
    // {
    //   title: "Round 3",
    //   value: "Technical Fundamentals Testr3",
    // },
    // {
    //   title: "Round 4",
    //   value: "Technical Fundamentals Testr4",
    // },
    // {
    //   title: "Round 5",
    //   value: "Technical Fundamentals Testr5",
    // },
  ]);
  const [showLoader, setShowLoader] = useState(false);

  const convertProgrammingAssessmentData = (assessmentData) => {
    const questions = assessmentData?.round1QuestionsResponse;

    let convertedData = [];

    questions.forEach((question, index) => {
      const convertedQuestion = {
        questionText: question.question,
        options: question.choices || [], // If choices exist, use them, otherwise, use an empty array
        answerText: question.correctAnswer, // Extracting answer text
        selectedAnswerText: question.developerAnswer || "", // Extracting developer answer text if available
        questionStatus: question.questionStatus,
        score: question.score,
        totalScore: question.totalScore,
      };
      convertedData.push(convertedQuestion);
    });
    return convertedData;
  };

  const getAlLDataRound1 = async () => {
    // allReportRound1;
    try {
      const data = {
        id: sessionStorage.getItem("view_report_user"),
      };
      const res = await allReportRound1(data);
      if (res) {
        // setQuestionData([...res]);
        const data = convertProgrammingAssessmentData(res);
        setQuestionData([...data]);
        // console.log("Converted", data);
      }
    } catch (err) {
      console.log("error in getAlLDataROund1=>", err);
    }
  };

  const getPerformanceR1 = async () => {
    try {
      const data = {
        id: sessionStorage.getItem("view_report_user"),
      };
      const res = await performanceSumaryRound1(data);
      if (res) {
        setPerformanceR1({ ...res });
      } else {
        setPerformanceR1({});
      }
    } catch (err) {
      console.log("error in getPerformanceR1=>", err);
    }
  };

  const getPerformanceR2 = async () => {
    try {
      const data = {
        name: sessionStorage.getItem("view_report_user"),
      };
      const res = await performanceSumaryRound2(data);
      if (res) {
        setPerformanceR1({ ...res });
      } else {
        setPerformanceR1({});
      }
    } catch (err) {
      console.log("error in getPerformanceR2=>", err);
    }
  };
  const getReportR2 = async () => {
    try {
      const data = {
        name: sessionStorage.getItem("view_report_user"),
      };
      const res = await performanceSumaryRound21(data);
      const res2 = await performanceSumaryRound22(data);
      if (res && res2) {
        setReportR2_1({ ...res });
        setReportR2_2({ ...res2 });
      } else {
        setReportR2_1({});
        setReportR2_2({});
      }
    } catch (err) {
      console.log("error in getReportR2=>", err);
    }
  };

  useEffect(() => {
    if (selectedRound === "Round 1") {
      getPerformanceR1();
      getAlLDataRound1();
    } else if (selectedRound === "Round 2") {
      // console.log("round changed,", selectedRound);
      getPerformanceR2();
    }
    return () => {
      // sessionStorage.removeItem("view_report_user");
    };
  }, [selectedRound]);

  useEffect(() => {
    console.log("view completed", viewCompleteReportR2);
    if (viewCompleteReportR2) {
      setViewCompleteReport(false);
      // setPerformanceR1();
      getReportR2();
    } else {
      setViewCompleteReportR2(false);
      setReportR2_1({});
      setReportR2_2({});
    }
  }, [viewCompleteReportR2]);

  return (
    <>
      {showLoader && <GlobalLoader></GlobalLoader>}
      <div className="dev-report" ref={componentRef}>
        <DevDetails
          roundsList={roundsList}
          setSelectedRound={setSelectedRound}
          selectedRound={selectedRound}
          performanceR1={performanceR1}
          pageInput={componentRef}
          setShowLoader={setShowLoader}
        />
        <div className="title">{selectedRound} : Assessment Report</div>

        {selectedRound === "Round 1" ? (
          <>
            {" "}
            <RoundReport
              selectedRound={selectedRound}
              setViewCompleteReport={setViewCompleteReport}
              performanceR1={performanceR1}
            />
            {viewCompleteReport && (
              <CompleteReport
                selectedRound={selectedRound}
                roundValue={roundValue}
                questionData={questionData}
                performanceR1={performanceR1}
              />
            )}
          </>
        ) : (
          <>
            <Round2Report
              selectedRound={selectedRound}
              performanceR2={performanceR1}
              setViewCompleteReportR2={setViewCompleteReportR2}
              viewCompleteReportR2={viewCompleteReportR2}
              roundValue={roundValue}
              reportR2_1={reportR2_1}
              reportR2_2={reportR2_2}
            />
          </>
        )}
      </div>
    </>
  );
};

const DevDetails = ({
  roundsList,
  setSelectedRound,
  selectedRound,
  performanceR1,
  pageInput,
  setShowLoader,
}) => {
  const [excludeForPdf, setExcludeForPdf] = useState(false);
  return (
    <div className="details">
      <div className="personal">
        <div className="name">
          {selectedRound === "Round 1"
            ? performanceR1?.developerName
            : performanceR1?.fullName}
        </div>
        <div className="submit">Submitted on</div>
        <div className="date">
          {selectedRound === "Round 1"
            ? performanceR1?.testEndTime
            : performanceR1?.submittedOn}
        </div>
      </div>
      {excludeForPdf ? (
        <></>
      ) : (
        <div className="data">
          <DownloadPage
            pageInput={pageInput}
            setExcludeForPdf={setExcludeForPdf}
            setShowLoader={setShowLoader}
          />
          <div className="roundNUm">
            <CustomDropDown
              options={roundsList}
              label={""}
              handleChange={(val) => setSelectedRound(val)}
              value={selectedRound}
              placeholder={""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const RoundReport = ({ setViewCompleteReport, performanceR1 }) => {
  return (
    <div className="round-report">
      <div className="data">
        <div style={{ border: "1px solid #DF7A03" }}>
          <div>
            <img src={icon6} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Total Score :{" "}
              <span style={{ color: "#DF7A03" }}>
                {" "}
                {typeof performanceR1?.totalScore === "number" &&
                  performanceR1?.totalScore.toFixed(2)}
              </span>
              / <span>100</span>
            </div>
          </div>
        </div>
        <div
          style={{
            border:
              performanceR1?.status?.toLowerCase() === "passed"
                ? "1px solid #0BB762"
                : "1px solid #783ADE",
          }}
        >
          <div>
            <img
              src={
                performanceR1?.status?.toLowerCase() === "passed"
                  ? icon8
                  : icon5
              }
              alt=""
            />
          </div>
          <div
            style={{
              color:
                performanceR1?.status?.toLowerCase() === "passed"
                  ? "#0BB762"
                  : "#DE3A3B",
            }}
          >
            {performanceR1?.status}
          </div>
        </div>
        <div style={{ border: "1px solid #E4AD15" }}>
          <div>
            <img src={icon1} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Question Attempted :{" "}
              <span style={{ color: "#E4AD15" }}>
                {performanceR1?.questionsAttempted}{" "}
              </span>
              / <span>{performanceR1?.totalQuestions}</span>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #34A853" }}>
          <div>
            <img src={icon4} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Correct Answer :{" "}
              <span style={{ color: "#34A853" }}>
                {performanceR1?.correctQuestionsAttempted}
              </span>
              / <span>{performanceR1?.totalQuestions}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="title" style={{ marginTop: "50px" }}>
        Test Time Analysis
      </div>
      <div className="data">
        <div style={{ border: "1px solid #0A4FA0" }}>
          <div>
            <img src={icon7} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Time Taken For Completion :{" "}
              <span style={{ color: "#0A4FA0" }}>
                {" "}
                {performanceR1?.totalTimeTaken}{" "}
              </span>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #0D8AA5" }}>
          <div>
            <img src={icon2} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Test Start Time :{" "}
              <span style={{ color: "#0D8AA5" }}>
                {performanceR1?.testStartTime}{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #AA0047" }}>
          <div>
            <img src={icon3} alt="" />
          </div>
          <div>
            <div>
              Test End Time :{" "}
              <span style={{ color: "#AA0047" }}>
                {" "}
                {performanceR1?.testEndTime}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="view-report">
        <button onClick={() => setViewCompleteReport(true)}>
          View Complete Report
        </button>
      </div>
    </div>
  );
};

const CompleteReport = ({
  selectedRound,
  roundValue,
  questionData,
  performanceR1,
}) => {
  return (
    <div className="assessment-report">
      <div className="top-group">
        <div className="round-num">
          <span> {selectedRound} </span>
          <span>
            {roundValue.find((item) => item.title === selectedRound)?.value}
          </span>
        </div>
        <div className="status">
          <div>
            Status : <span> {performanceR1?.status}</span>
          </div>
          <div>
            Final Score:{" "}
            <span>
              {performanceR1?.correctQuestionsAttempted}/{" "}
              {performanceR1?.totalQuestions}
            </span>
          </div>
        </div>
      </div>
      <DisplayQuestionData questionData={questionData} />
    </div>
  );
};

const DisplayQuestionData = ({ questionData }) => {
  return (
    <div className="question-data">
      {questionData.map((item, index) => (
        <div key={index} className="each">
          <div className="num">
            <div>Question : {index + 1}</div>
            <div className="answer">
              <div
                style={{
                  border:
                    item?.questionStatus === "Incorrect"
                      ? "1px solid #E15140"
                      : "",
                  color: item?.questionStatus === "Incorrect" ? "#E15140" : "",
                }}
              >
                {" "}
                {item?.questionStatus}
              </div>
              <div>
                {item?.score} / {item?.totalScore}
              </div>
            </div>
          </div>
          <div className="desc">{item?.questionText}</div>
          <div className="options">
            {item?.options?.map((option, index) => (
              <div key={index}>
                {item?.answerText === option && (
                  <div className="correct">Correct answer</div>
                )}
                {item?.selectedAnswerText === option && (
                  <div className="candidate">Candidate answer</div>
                )}
                {option}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const DownloadPage = ({ pageInput, setExcludeForPdf, setShowLoader }) => {
  const options = {
    filename: "download.pdf",
    page: {
      margin: 10,
    },
  };
  const classNames = ["top-group", "details"];
  const { resetStickyPositions, restoreStickyPositions } = useStickyElements(
    pageInput,
    classNames
  );

  const handleGeneratePdf = () => {
    setExcludeForPdf(true);
    setShowLoader(true);

    resetStickyPositions();

    setTimeout(async () => {
      try {
        await generatePDF(pageInput, options);
      } finally {
        // Restore sticky positions
        restoreStickyPositions();

        setExcludeForPdf(false);
        setShowLoader(false);
      }
    }, 0);
  };

  return (
    <button className="download" onClick={handleGeneratePdf}>
      DOWNLOAD
    </button>
  );
};
