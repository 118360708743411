import Popup from "../popUp/popUp";
import close from "../../../Assessts/SVGs/Close.svg";
import "./Dialog.scss"
export const Dialog = ({ handleClose, data }) => {
  return (
    <Popup>
      <div className="dialog-main">
        <div className="dialog-close" onClick={() => handleClose()}>
          <img src={close} alt="" />
        </div>
        <div>
          <img src={data?.icon} alt="" />
        </div>
        <div className="dialog-title1">{data?.message1}</div>
        <div className="dialog-title2">{data?.message2}</div>
        <button className="dialog-okay" onClick={() => handleClose()}>
          Okay
        </button>
      </div>
    </Popup>
  );
};
