import { useEffect, useState } from "react";
import "./ViewRecordings.scss";
import { SubHeaderRoutes } from "../../Components/ViewRecordings/SubHeaderRoutes/SubHeaderRoutes";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { VideoRecording } from "../../Components/ViewRecordings/VideoRecording/VideoRecording";
import { ScreenRecording } from "../../Components/ViewRecordings/ScreenRecording/ScreenRecording";
import { styled } from "@mui/system";

import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { Close } from "@mui/icons-material";
// import { actionFlagUser } from "../../APIs/viewRecordings";
import { CommonDialog } from "../../Components/common/Dialog/CommonDialog";
import { flagInfo } from "../../mockData/interview";
import { BackButton } from "../../Components/common/BackButton/BackButton";

const CustomRadio = styled(Radio)({
  color: "#858585",
  "&.Mui-checked": {
    color: "#062B6E",
  },
});

export const ViewRecordings = () => {
  const [loading, setLoading] = useState(false);
  const [selecetdIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams?.get("user") || "";
  const round = queryParams?.get("round") || "";
  const { flaggedInfo, isFlag, candidateName, back } = location.state || {};
  const navigate = useNavigate();
  const handleBackButton = () => {
    if (back) navigate(-1);
    else navigate("/candidate-details", { state: location.state });
  };
  return (
    <div className="view-recordings-Container">
      <BackButton
        name={candidateName}
        handleBackButton={handleBackButton}
      ></BackButton>
      <FlagUser
        userEmail={userEmail}
        round={round}
        flaggedInfo={flaggedInfo}
        isFlag={isFlag}
      />
      <SubHeaderRoutes
        selecetdIndex={selecetdIndex}
        setSelectedIndex={setSelectedIndex}
      />
      {["Approved", "Pending"].includes(flaggedInfo?.flaggedStatus) && (
        <FlagDesc comment={flaggedInfo?.flaggedComments} />
      )}
      <Routes>
        <Route
          path="/video"
          element={<VideoRecording setLoading={setLoading} />}
        />
        <Route
          path="/screen"
          element={<ScreenRecording setLoading={setLoading} />}
        />
        <Route path="/" element={<Navigate to="video" />} />
      </Routes>
    </div>
  );
};

const FlagDesc = ({ comment }) => {
  return (
    <div className="flag-desc">
      <span className="title">Flag Reasoning : </span>
      <span className="reason">{comment}</span>
    </div>
  );
};

const FlagUser = ({ userEmail, round, flaggedInfo, isFlag }) => {
  const [open, setOpen] = useState(false);
  const [showCommonDialog, setShowCommonDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const navigate = useNavigate();
  const handleFlagDialogOpen = () => {
    setOpen(true);
  };

  const handleFlagDialogClose = () => {
    setOpen(false);
  };

  // const handleSubmit = async (data) => {
  //   const res = await actionFlagUser(data);
  //   if (res) {
  //     navigate("/interview");
  //   } else {
  //     setShowCommonDialog(true);
  //     setDialogData({ ...flagInfo?.error });
  //   }
  // };
  const handleSubmit = () => {};

  const handleApproveDialogClose = () => {
    setShowApproveDialog(false);
  };

  // const handleApproveSubmit = async (data, isApprove) => {
  //   const res = await actionFlagUser(data);

  //   if (res) {
  //     if (flaggedInfo?.flaggedStatus === "Approved") {
  //       navigate("/interview");
  //     } else {
  //       setDialogData(
  //         isApprove ? { ...flagInfo.approved } : { ...flagInfo.rejected }
  //       );
  //       setShowCommonDialog(true);
  //     }
  //   } else {
  //     setDialogData({ ...flagInfo.error });
  //     setShowCommonDialog(true);
  //   }
  // };
  const handleApproveSubmit = () => {};
  const handleCommonDialogClose = () => {
    setShowCommonDialog(false);
    navigate("/interview");
  };

  const handleApproveDialogOpen = () => {
    setShowApproveDialog(true);
  };

  return (
    <div className="flag-user">
      {flaggedInfo?.flaggedStatus === "Pending" && (
        <IconButton className="approve" onClick={handleApproveDialogOpen}>
          <span>Approve Flag</span>
        </IconButton>
      )}
      {/* {flaggedInfo?.flaggedStatus === "Approved" && (
        <IconButton className="reject" onClick={handleApproveDialogOpen}>
          <FlagIcon /> <span>Unflag User</span>
        </IconButton>
      )} */}
      <Tooltip title="In case candidate is found to use unethical means then click this button">
        <IconButton>
          {/* <FlagIcon /> */}
          {/* <span>{flaggedInfo?.userFlagged ? "Edit Flag" : "Flag User"}</span> */}
        </IconButton>
      </Tooltip>
      <FlagDialog
        open={open}
        onClose={handleFlagDialogClose}
        onSubmit={handleSubmit}
        userEmail={userEmail}
        round={round}
        flaggedInfo={flaggedInfo}
      />
      <ApproveDialog
        open={showApproveDialog}
        onClose={handleApproveDialogClose}
        onSubmit={handleApproveSubmit}
        flaggedInfo={flaggedInfo}
        userEmail={userEmail}
        round={round}
      ></ApproveDialog>
      {showCommonDialog && (
        <CommonDialog
          buttonsList={{
            Okay: {
              name: "Okay",
              action: handleCommonDialogClose,
            },
            Close: {
              name: "Close",
              action: handleCommonDialogClose,
            },
          }}
          data={dialogData}
        ></CommonDialog>
      )}
    </div>
  );
};

const ApproveDialog = ({
  open,
  onClose,
  onSubmit,
  userEmail,
  round,
  flaggedInfo,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => setSelectedValue(event.target.value);
  const handleSubmit = () => {
    const userData = {
      candidateEmail: userEmail,
      round,
      flaggedComments: flaggedInfo?.flaggedComments,
    };
    const isApproved = flaggedInfo?.flaggedStatus === "Approved";
    const userFlagged = selectedValue === "yes" ? !isApproved : isApproved;

    onSubmit({ userFlagged, ...userData }, selectedValue === "yes");
    onClose();
  };

  const handleClose = () => {
    setSelectedValue("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="flag-user-container"
      maxWidth="xs"
      fullWidth
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiDialog-paper": {
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      <DialogActions className="first">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogTitle className="title">
        {flaggedInfo?.flaggedStatus === "Approved"
          ? "Are you sure you want to unflag this user?"
          : "Do you want to approve the flag raised by HR?"}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
          className="radio-button"
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </DialogContent>
      <DialogActions className="button-container">
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedValue}
          className="submit-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FlagDialog = ({
  open,
  onClose,
  onSubmit,
  userEmail,
  round,
  flaggedInfo,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (open && flaggedInfo?.userFlagged) {
      setMessage(flaggedInfo?.flaggedComments || "");
      setSelectedValue("yes");
    }
  }, [open, flaggedInfo]);

  const handleChange = (event) => setSelectedValue(event.target.value);

  const handleMessageChange = (event) => setMessage(event.target.value);

  const handleSubmit = () => {
    const userData = {
      candidateEmail: userEmail,
      round,
      flaggedComments: message,
      userFlagged: selectedValue === "yes",
    };
    onSubmit(userData);
    onClose();
  };

  const handleClose = () => {
    setSelectedValue("");
    setMessage("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="flag-user-container"
      maxWidth="xs"
      fullWidth
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiDialog-paper": {
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      <DialogActions className="first">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogTitle className="title">
        Are you sure you want to flag this user?
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
          className="radio-button"
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
        {selectedValue === "yes" && (
          <>
            <TextField
              className="message"
              aria-label="Message"
              multiline
              rows={4}
              placeholder="Write comment..."
              fullWidth
              variant="outlined"
              value={message}
              onChange={handleMessageChange}
            />
            <div className="info">
              *Provide detailed explanation and time stamp for integrity issues.
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className="button-container">
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedValue}
          className="submit-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
