import axios from "axios";
import { CITY_LIST, COUNTRY_LIST, STATE_LIST, USA_CITY_LIST, USA_STATE_LIST } from "./URLS";

export const getCountries = async () => {
    try {
        const urlEndpoint = COUNTRY_LIST;
        // const Config = {};
        return await axios.get(urlEndpoint).then(res => res.data);
    } catch (err) {
        console.log("countries error ==>", err.message);
    }
}

export const getStates = async (data) => {
    try {
        const usaStateList = USA_STATE_LIST;
        const otherStateList = STATE_LIST;
        // const data = {
        //     countryName: value,
        // };

        const urlEndpoint =
            data["countryName"] === "United States" ? usaStateList : otherStateList;

        const Config = {};
        return await axios.post(urlEndpoint, data, Config).then((response) => response.data);
    } catch (err) {
        console.log("states error ==>", err.message);
    }
}


export const getCities = async (data) => {
    try {
        const usaCityList = USA_CITY_LIST;
        const otherCityList = CITY_LIST;

        // const data = {
        //     countryName: formData?.country ? formData?.country : country,
        //     stateName: value,
        // };

        const urlEndpoint =
            data["countryName"] === "United States" ? usaCityList : otherCityList;

        const Config = {};
        return await axios.post(urlEndpoint, data, Config).then((response) => response.data)
    } catch (err) {
        console.log("states error ==>", err.message);
    }
}


