import { Pagination } from "@mui/material";
import "./Pagination_data.scss";

export const TablePages = ({ page, handlePageChange }) => {
  return (
    <div className="page-wrapper">
      {/* Your paginated content here */}
      <Pagination
        count={50} // Total number of pages
        page={page} // Current page
        onChange={handlePageChange} // Callback function for page change
        color="primary" // Color of the pagination buttons
        size="large" // Size of the pagination buttons
        variant="outlined" // Variant of the pagination buttons
        shape="rounded" // Shape of the pagination buttons
      />
    </div>
  );
};
